var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bg fill-height" },
    [
      _c(
        "v-container",
        { staticClass: "fill-height ma-0 pa-0", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            {
              staticClass: "fill-height pa-0 ma-0",
              attrs: { align: "center", justify: "center" }
            },
            [
              this.isOauthStateValid
                ? _c(
                    "v-card",
                    {
                      staticClass: "pa-5 ma-1",
                      attrs: { elevation: "17", raised: "", width: "380px" }
                    },
                    [
                      _c("v-container", { staticClass: "ma-0 logo-wrapper" }, [
                        _c("img", {
                          attrs: { src: require("../assets/mappa_logo.png") }
                        })
                      ]),
                      _c("v-card-title", [_vm._v("Cadastro")]),
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-form",
                            {
                              ref: "register-form",
                              attrs: {
                                id: "form-register-basic",
                                name: "form-register-basic",
                                novalidate: ""
                              },
                              on: {
                                submit: function($event) {
                                  $event.preventDefault()
                                  return _vm.validateRegister($event)
                                }
                              }
                            },
                            [
                              _c(
                                "v-container",
                                { staticClass: "pa-0", attrs: { fluid: "" } },
                                [
                                  _c(
                                    "v-row",
                                    { staticClass: "ma-0" },
                                    [
                                      _c("v-text-field", {
                                        staticClass: "mx-0 mt-4",
                                        attrs: {
                                          id: "firstName",
                                          name: "firstName",
                                          label: "Nome",
                                          color: "accent",
                                          outlined: "",
                                          "error-messages": _vm.nameErros
                                        },
                                        model: {
                                          value: _vm.form.name,
                                          callback: function($$v) {
                                            _vm.$set(_vm.form, "name", $$v)
                                          },
                                          expression: "form.name"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { staticClass: "ma-0" },
                                    [
                                      _c("phone-input", {
                                        staticClass: "mx-0",
                                        attrs: {
                                          id: "telefone",
                                          name: "telefone",
                                          label: "Telefone",
                                          color: "accent",
                                          outlined: "",
                                          "error-messages":
                                            _vm.phoneNumberErrors
                                        },
                                        model: {
                                          value: _vm.form.phoneNumber,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "phoneNumber",
                                              $$v
                                            )
                                          },
                                          expression: "form.phoneNumber"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { staticClass: "ma-0" },
                                    [
                                      _c("v-text-field", {
                                        staticClass: "mx-0",
                                        attrs: {
                                          id: "email",
                                          name: "email",
                                          label: "E-mail",
                                          color: "accent",
                                          outlined: "",
                                          "error-messages": _vm.emailErrors
                                        },
                                        model: {
                                          value: _vm.form.email,
                                          callback: function($$v) {
                                            _vm.$set(_vm.form, "email", $$v)
                                          },
                                          expression: "form.email"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { staticClass: "ma-0" },
                                    [
                                      _c("v-text-field", {
                                        staticClass: "mx-0",
                                        attrs: {
                                          label: "Senha",
                                          color: "accent",
                                          outlined: "",
                                          "error-messages": _vm.passwordErrors,
                                          "append-icon": _vm.showPassword
                                            ? "mdi-eye"
                                            : "mdi-eye-off",
                                          type: _vm.showPassword
                                            ? "password"
                                            : "text"
                                        },
                                        on: {
                                          "click:append": function($event) {
                                            _vm.showPassword = !_vm.showPassword
                                          }
                                        },
                                        model: {
                                          value: _vm.form.password,
                                          callback: function($$v) {
                                            _vm.$set(_vm.form, "password", $$v)
                                          },
                                          expression: "form.password"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "mx-0",
                                      attrs: {
                                        align: "center",
                                        justify: "start"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        { staticClass: "politics" },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              "Ao criar sua conta, você indica que está de acordo com os nossos"
                                            )
                                          ]),
                                          _c(
                                            "router-link",
                                            {
                                              attrs: {
                                                to: "/terms-of-use",
                                                target: "_blank"
                                              }
                                            },
                                            [_vm._v(" Termos de Uso")]
                                          ),
                                          _vm._v(" e "),
                                          _c(
                                            "router-link",
                                            {
                                              attrs: {
                                                to: "/privacy-policy",
                                                target: "_blank"
                                              }
                                            },
                                            [_vm._v(" Política de Privacidade")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { staticClass: "ma-0 mt-5" },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "mx-0",
                                          attrs: {
                                            color: "accent",
                                            block: "",
                                            type: "submit",
                                            loading: _vm.isLoading,
                                            disabled:
                                              _vm.isLoading || !_vm.isVerified
                                          }
                                        },
                                        [_vm._v(" Cadastrar ")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("v-divider", { staticClass: "my-4" }),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mx-0",
                          attrs: {
                            to: "/login",
                            small: "",
                            block: "",
                            text: "",
                            color: "primary"
                          }
                        },
                        [_vm._v(" Já sou cadastrado ")]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }