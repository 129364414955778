<template>
  <div
    class="bg fill-height"
  >
    <v-container
      fluid
      class="fill-height ma-0 pa-0"
    >
      <v-row
        align="center"
        justify="center"
        class="fill-height pa-0 ma-0"
      >
        <v-card
          v-if="this.isOauthStateValid"
          elevation="17"
          raised
          class="pa-5 ma-1"
          width="380px"
        >
          <v-container class="ma-0 logo-wrapper">
            <img src="../assets/mappa_logo.png"/>
          </v-container>

          <v-card-title>Cadastro</v-card-title>

          <v-card-text>
            <v-form
              id="form-register-basic"
              name="form-register-basic"
              novalidate
              ref="register-form"
              @submit.prevent="validateRegister"
            >
              <v-container
                fluid
                class="pa-0"
              >
                <v-row
                  class="ma-0"
                >
                  <v-text-field
                    id="firstName"
                    name="firstName"
                    label="Nome"
                    color="accent"
                    outlined
                    v-model="form.name"
                    :error-messages="nameErros"
                    class="mx-0 mt-4"
                  >
                  </v-text-field>
                </v-row>
                <v-row
                  class="ma-0"
                >
                  <phone-input
                    id="telefone"
                    name="telefone"
                    v-model="form.phoneNumber"
                    label="Telefone"
                    color="accent"
                    outlined
                    class="mx-0"
                    :error-messages="phoneNumberErrors"
                  />
                </v-row>
                <v-row
                  class="ma-0"
                >
                  <v-text-field
                    id="email"
                    name="email"
                    label="E-mail"
                    color="accent"
                    outlined
                    class="mx-0"
                    v-model="form.email"
                    :error-messages="emailErrors"
                  >
                  </v-text-field>
                </v-row>
                <v-row
                  class="ma-0"
                >
                  <v-text-field
                    label="Senha"
                    color="accent"
                    outlined
                    class="mx-0"
                    v-model="form.password"
                    :error-messages="passwordErrors"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPassword ? 'password': 'text' "
                    @click:append="showPassword = !showPassword"

                  >
                  </v-text-field>
                </v-row>
                <v-row
                  align="center"
                  justify="start"
                  class="mx-0"
                >
                  <v-col class="politics">
                    <span>Ao criar sua conta, você indica que está de acordo com os nossos</span>
                    <router-link to="/terms-of-use" target="_blank"> Termos de Uso</router-link>
                    e
                    <router-link to="/privacy-policy" target="_blank"> Política de Privacidade</router-link>
                  </v-col>
                </v-row>
                <v-row
                  class="ma-0 mt-5"
                >
                  <v-btn
                    color="accent"
                    block
                    type="submit"
                    :loading="isLoading"
                    :disabled="isLoading || !isVerified"
                    class="mx-0"
                  >
                    Cadastrar
                  </v-btn>
                </v-row>
                <!--                <v-row-->
                <!--                  align="center"-->
                <!--                  justify="center"-->
                <!--                >-->
                <!--                  <vue-recaptcha-->
                <!--                    sitekey="6Lczu8UUAAAAAMsPXe98-Cp0tjm9dxQqH1YrIEiE"-->
                <!--                    :loadRecaptchaScript="true"-->
                <!--                    ref="recaptchaComponent"-->
                <!--                    @verify="onRecaptchaVerify"-->
                <!--                    @expired="onRecaptchaExpired"-->
                <!--                    @error="onRecaptchaError"-->
                <!--                  >-->
                <!--                  </vue-recaptcha>-->
                <!--                </v-row>-->
              </v-container>
            </v-form>
          </v-card-text>
          <v-divider
            class="my-4"
          >
          </v-divider>
          <v-btn
            to="/login"
            small
            block
            text
            color="primary"
            class="mx-0"
          >
            Já sou cadastrado
          </v-btn>
        </v-card>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import {mapActions, mapGetters} from "vuex"
  import {validationMixin} from "vuelidate"
  import {email, required} from "vuelidate/lib/validators"
  import {GET_FULL_STATE, GET_IS_OAUTH_STATE_VALID, SET_OAUTH_STATE} from "../store/modules/oauth"
  import {RegisterBadRequest} from "../helpers/auth-horus"
  import {NOTIFICATION_TYPES, NotificationOptions} from "../helpers/notify"
  import {PLATFORM_URL} from "../globals"
  import PhoneInput from "../components/Helpers/PhoneInput"
  // import VueRecaptcha from "vue-recaptcha"

  export default {
    name: "Register",
    components: {PhoneInput},
    // components: {VueRecaptcha},
    mixins: [validationMixin],
    data: () => ({
      form: {
        name: null,
        email: null,
        phoneNumber: null,
        password: null,
      },
      showPassword: true,
      isLoading: false,
      isVerified: true,

      subscriptionName: null,
      subscriptionDescription: null,

      errors: {
        uniqueEmailError: false,
      },

      isFreeToRegister: false,
    }),
    validations: {
      form: {
        name: {
          required,
        },
        phoneNumber: {
          required,
          isValid: function () {
            if (!this.form.phoneNumber) return false
            return this.form.phoneNumber.isValid()
          },
        },
        email: {
          required,
          email,
          uniqueEmail() {
            return !this.errors.uniqueEmailError
          },
        },
        password: {
          required,
        },
      },
    },
    methods: {
      validateRegister() {
        this.$v.$touch()

        if (this.$v.$invalid) {
          return
        }

        this.isLoading = true

        const name = `${this.form.name}`
        const email = `${this.form.email}`
        const phoneNumber = this.form.phoneNumber
        const username = `${this.form.email}`
        const password = `${this.form.password}`
        const clientId = this.oauthFullState.client_id
        const redirectUri = this.oauthFullState.redirect_uri

        let referralToken = this.$route.query["rft"]
        if (referralToken) {
          referralToken = referralToken.split("?rft=")[0]
        }
        let register_key = this.$route.query["register_key"]
        const pb = phoneNumber.toJSON()

        // If the URL has this "codeFreeRegister" it Register the user with no Stripe redirect, else the user has to go to Stripe to register a Credit card.
        if (this.isFreeToRegister) {

          this.$apiAuth.registerUser(
            username, password, email,
            name, pb.number["significant"], pb["regionCode"],
            pb["regionCode"].toUpperCase(), referralToken,
            clientId, redirectUri, register_key
          ).then(() => {
              this._userRegisterSuccess(username, password)
            })
            .catch(this._userRegisterFailed)

        } 
        else {

          this.$apiAuth.createRegisterCheckoutSession(
          username, password, email,
          name, pb.number["significant"], pb["regionCode"],
          pb["regionCode"].toUpperCase(), referralToken, clientId, 
          redirectUri, register_key
          ).then((response) => {
            const stripeUrl = response.data
            window.location.href = stripeUrl
          })
          .catch(this._userRegisterFailed)

        }
      },
      _userRegisterSuccess(username, password) {
        if(!(window.localStorage.getItem("rq") == "horus_partner")){
            window.localStorage.removeItem("rq")
        }
        this.$apiAuth.loginUser(username, password)
          .then(() => {
            this.$router.push("/post-register")
          })
          .catch(() => {
            this.$router.push("/login")
            this.$notify(new NotificationOptions({
              message: "Ocorreu um erro enquanto processávamos o seu registro, " +
                "por favor, tente fazer login com seus dados recém cadastrado",
              type: NOTIFICATION_TYPES.ERROR,
            }))
          })
      },
      _userRegisterFailed(err) {
        let message = "Um erro inesperado aconteceu, por favor, tente novamente mais tarde."
        if (!(err instanceof RegisterBadRequest)) {
          this.$notify(new NotificationOptions({
            message: message,
            type: NOTIFICATION_TYPES.ERROR,
          }))

          this.isLoading = false
          return
        }

        const error = err.data.error
        if (error === "unique-email-error" || error === "unique-username-error") {
          // message = "E-mail já existente. Por favor realize o cadastro com outro e-mail."
          this.errors.uniqueEmailError = true
          this.isLoading = false

          this.$v.$touch()
          return
        }
        if (error === "register-key-doesnt-exists") {
          message = "Register Key Inválida. Por favor, utilize outra Chave de Registro"
          this.$notify(new NotificationOptions({
            message: message,
            type: NOTIFICATION_TYPES.ERROR,
          }))
          this.$router.push("/invalid-register-key")
        }


        this.$v.$reset()
        this.isLoading = false
      },

      onRecaptchaVerify() {
        this.isVerified = true
      },
      onRecaptchaExpired() {
        const recaptcha = this.$refs["recaptchaComponent"]
        recaptcha.reset()
      },
      onRecaptchaError() {
        this.$notify(new NotificationOptions({
          message: "Possibilidade de bot",
          type: NOTIFICATION_TYPES.ERROR,
        }))
      },

      ...mapActions({
        setOauthState: SET_OAUTH_STATE,
      }),
    },
    created() {
      const finalQuery = this.$route.query
      this.setOauthState(finalQuery)

      if (!this.isOauthStateValid) {
        // TODO: Define a redirect for a base client, most likely the Mappa Client
        // In a way that can be dynamically set depending of the env (dev, prod, etc)
        window.localStorage.setItem("rq",finalQuery.register_key)
        window.location.replace(`${PLATFORM_URL}/register?register_key=${finalQuery.register_key}`)
      }

      const login_hint = this.oauthFullState.login_hint
      if (login_hint) {
        this.form.email = login_hint
      }
    },
    mounted() {
      this.$apiAuth.freeToRegister(this.$route.query["register_key"])
        .then((response) => {
          this.isFreeToRegister = response.data
        })
        .catch(() => {
          this.$notify(new NotificationOptions({
            message: "Ocorreu um erro ao carregar a página.",
            type: NOTIFICATION_TYPES.ERROR,
          }))
        })
    },
    computed: {
      authorizePostUrl() {
        return this.$apiAuth.createUrl(this.$apiAuth.authorizePath)
      },
      // Validation errors
      nameErros() {
        const errors = []
        if (!this.$v.form.name.$dirty) return errors
        !this.$v.form.name.required && errors.push("Campo obrigatório")
        return errors
      },
      phoneNumberErrors() {
        const errors = []
        if (!this.$v.form.phoneNumber.$dirty) return errors
        !this.$v.form.phoneNumber.required && errors.push("Campo obrigatório")
        !this.$v.form.phoneNumber.isValid && errors.push("Telefone inválido")
        return errors
      },
      emailErrors() {
        const errors = []
        if (!this.$v.form.email.$dirty) return errors
        !this.$v.form.email.required && errors.push("Campo obrigatório")
        !this.$v.form.email.email && errors.push("E-mail inválido")
        !this.$v.form.email.uniqueEmail && errors.push("Este e-mail já está em uso")
        return errors
      },
      passwordErrors() {
        const errors = []
        if (!this.$v.form.password.$dirty) return errors
        !this.$v.form.password.required && errors.push("Campo obrigatório")
        return errors
      },
      ...mapGetters({
        oauthFullState: GET_FULL_STATE,
        isOauthStateValid: GET_IS_OAUTH_STATE_VALID,
      }),
    },
    watch: {
      "form.email"() {
        this.$v.form.email.$reset()
        this.errors.uniqueEmailError = false
      },
    },
  }

</script>

<style scoped lang="scss">
  @import "../sass/mixins";

  .bg {
    background-image: url("../assets/login/background_image.jpg");
    background-size: cover;
  }

  .v-card {
    max-width: 380px;

    @include display-xs-only {
      width: 100% !important;
      min-height: 100% !important;
      padding: 10px;
      margin: 0px !important;
    }
  }

  .v-card__text {
    @include display-xs-only {
      padding: 0px !important;

    }
  }

  .politics {
    text-align: left;
    word-wrap: break-word;
    white-space: normal;
    padding: 0px;
  }

  .logo-wrapper {
    text-align: center;
  }

  .logo-wrapper img {
    height: 70px;
  }
</style>
