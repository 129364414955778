<template>
  <v-row
    justify="center"
    align="end"
    class="d-flex"
    no-gutters
  >
    <v-col
      cols="3"
      class="pa-0"
    >
      <v-select
      v-model="phoneObject"
      :items="countries"
      item-text="name"
      :error-messages="phoneObjectErrors"
      return-object
      :outlined="$attrs.outlined"
      :disabled="$attrs.disabled"
      no-filter
    >
      <template v-slot:selection="{ item }">
        <div :class="['vti__flag', item.iso2.toLowerCase()]"/>
      </template>
      <template v-slot:item="{ item }">
        <div :class="['vti__flag', item.iso2.toLowerCase()]"/>
        <strong class="px-2">{{ item.name }}</strong>
        <span>
            +{{ item.dialCode }}
        </span>
      </template>
    </v-select>
    </v-col>
    <v-col
      cols="9"
      class="pa-0"
    >
      <v-text-field
      v-model="phoneNumber"
      v-mask="phoneNumberMasks"
      :placeholder="phoneNumberPlaceholder"
      class="px-1"
      @input="onPhoneNumberInput"
      v-bind="$attrs"
    />
    </v-col>
  </v-row>
</template>

<script>
  import PhoneNumber from "awesome-phonenumber"
  import allCountries from "../../helpers/all-countries"
  import {validationMixin} from "vuelidate"
  import required from "vuelidate/lib/validators/required"
  import {isEqual} from "lodash/lang"
  import {filter, find, includes, map, sortBy} from "lodash/collection"

  function createMaskTemplate(nationalNumber) {
    return nationalNumber.replace(/\d/g, "#")
  }

  const preferredCountries = ["BR", "AO", "AR", "AU", "BO", "CL", "CN", "CO", "EC", "FR", "DE", "IN", "IT", "JP", "MZ", "PY", "PT", "UR", "PE", "VE", "ZA", "ES", "UK", "US", "ANOTHER"]

  export default {
    name: "PhoneInput",
    mixins: [validationMixin],
    model: {
      prop: "model",
      event: "change",
    },
    props: {
      model: [String, Object, PhoneNumber],
      value: [String],
      defaultCountry: {
        // Default country code, ie: 'AU'
        // Will override the current country of user
        type: String,
        default: "BR",
      },
      preferredCountries: {
        type: Array,
        default: () => preferredCountries,
      },
    },
    data: () => ({
      phoneObject: null,
      phoneNumber: null,
    }),
    validations() {
      return {
        phoneObject: {
          required,
        },
      }
    },
    methods: {
      onPhoneNumberInput(value) {
        if (!this.phoneObject) return
        const pb = new PhoneNumber(value, this.phoneObject.iso2)
        this.$emit("change", pb)
      },
    },
    computed: {
      countries() {
        let preferredCountries = filter(allCountries, value => includes(this.preferredCountries, value.iso2.toUpperCase()))
        preferredCountries = map(preferredCountries, country => ({...country, preferred: true}))
        preferredCountries = sortBy(preferredCountries, country => {
          if (isEqual(country.iso2.toUpperCase(), this.defaultCountry.toUpperCase())) return 0
          return 1
        })
        const filteredCountries = filter(allCountries, value => !includes(this.preferredCountries, value.iso2.toUpperCase()))
        return [...preferredCountries, ...filteredCountries]
      },
      phoneNumberPlaceholder() {
        if (!this.phoneObject) return ""
        return PhoneNumber.getExample(this.phoneObject.iso2.toLowerCase(), "mobile").getNumber("national")
      },
      phoneNumberMasks() {
        if (!this.phoneObject) return ""
        const mobile = PhoneNumber.getExample(this.phoneObject.iso2.toLowerCase(), "mobile").getNumber("national")
        const fixed = PhoneNumber.getExample(this.phoneObject.iso2.toLowerCase(), "fixed-line").getNumber("national")
        return [createMaskTemplate(mobile), createMaskTemplate(fixed)]
      },
      phoneObjectErrors() {
        const errors = []
        if (!this.$v.phoneObject.$dirty) return errors
        !this.$v.phoneObject.required && errors.push("Esse campo é obrigatório")
        return errors
      },
    },
    watch: {
      model(newValue) {
        if (newValue) {
          if (newValue.isValid()) {
            this.phoneObject = find(allCountries, value => isEqual(value.iso2.toLowerCase(), newValue["a"]["regionCode"].toLowerCase()))
            this.phoneNumber = newValue["a"]["number"]["significant"]
          }
        }
      },
    },
    created() {
      this.phoneObject = find(allCountries, value => isEqual(value.iso2.toUpperCase(), this.defaultCountry.toUpperCase()))
    },
  }
</script>

<style scoped lang="scss">
  @import "../../sass/countries";

  .v-select {
    ::v-deep &.v-input {
      flex: 0 1 0;
    }

    ::v-deep .v-select__slot {
      width: auto;
    }

    ::v-deep .v-select__selections {
      input {
        display: none;
      }
    }
  }

</style>
