var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    {
      staticClass: "d-flex",
      attrs: { justify: "center", align: "end", "no-gutters": "" }
    },
    [
      _c(
        "v-col",
        { staticClass: "pa-0", attrs: { cols: "3" } },
        [
          _c("v-select", {
            attrs: {
              items: _vm.countries,
              "item-text": "name",
              "error-messages": _vm.phoneObjectErrors,
              "return-object": "",
              outlined: _vm.$attrs.outlined,
              disabled: _vm.$attrs.disabled,
              "no-filter": ""
            },
            scopedSlots: _vm._u([
              {
                key: "selection",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c("div", { class: ["vti__flag", item.iso2.toLowerCase()] })
                  ]
                }
              },
              {
                key: "item",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c("div", {
                      class: ["vti__flag", item.iso2.toLowerCase()]
                    }),
                    _c("strong", { staticClass: "px-2" }, [
                      _vm._v(_vm._s(item.name))
                    ]),
                    _c("span", [_vm._v(" +" + _vm._s(item.dialCode) + " ")])
                  ]
                }
              }
            ]),
            model: {
              value: _vm.phoneObject,
              callback: function($$v) {
                _vm.phoneObject = $$v
              },
              expression: "phoneObject"
            }
          })
        ],
        1
      ),
      _c(
        "v-col",
        { staticClass: "pa-0", attrs: { cols: "9" } },
        [
          _c(
            "v-text-field",
            _vm._b(
              {
                directives: [
                  {
                    name: "mask",
                    rawName: "v-mask",
                    value: _vm.phoneNumberMasks,
                    expression: "phoneNumberMasks"
                  }
                ],
                staticClass: "px-1",
                attrs: { placeholder: _vm.phoneNumberPlaceholder },
                on: { input: _vm.onPhoneNumberInput },
                model: {
                  value: _vm.phoneNumber,
                  callback: function($$v) {
                    _vm.phoneNumber = $$v
                  },
                  expression: "phoneNumber"
                }
              },
              "v-text-field",
              _vm.$attrs,
              false
            )
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }